import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import {
    PRIMARY, BCA_BLUE, SECONDARY, WHITE,
} from '@theme_color';
import { FONT_24, FONT_REGULAR } from '@theme_typography';
import { BREAKPOINTS } from '@theme_vars';

// Create a theme instance.
const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto',
            'sans-serif',
        ],
    },
    palette: {
        primary: {
            main: PRIMARY,
        },
        secondary: {
            main: SECONDARY,
        },
        error: {
            main: red.A400,
        },
        background: {
            default: WHITE,
        },
    },
    breakpoints: {
        values: {
            ...BREAKPOINTS,
        },
    },
    overrides: {
        MuiGrid: {
            root: {
                padding: 0,
                margin: 0,
            },
        },
        MuiTextField: {
            root: {
                ...FONT_REGULAR,
            },
        },
        MuiTypography: {
            root: {
                '&.text-button': {
                    color: BCA_BLUE,
                },
            },
            h1: {
                ...FONT_24,
            },
        },
        MuiRadio: {
            root: {
                color: PRIMARY,
            },
            colorPrimary: {
                '&$checked': {
                    color: BCA_BLUE,
                },
            },
        },
        MuiFormControlLabel: {
            label: {
                ...FONT_REGULAR,
            },
            root: {
                marginBottom: -15,
            },
        },
        MuiDrawer: {
            paperAnchorRight: {
                background: 'transparent',
                boxShadow: 'none',
            },
        },
        MuiIcon: {
            root: {
                color: PRIMARY,
            },
        },
        MuiButton: {
            root: {
                borderRadius: 5,
            },
            containedPrimary: {
                backgroundColor: BCA_BLUE,
                '&:hover': {
                    backgroundColor: BCA_BLUE,
                },
            },
        },
        MuiBadge: {
            colorPrimary: {
                backgroundColor: BCA_BLUE,
            },
        },
        MuiPaper: {
            root: {
                '&.payment-method': {
                    backgroundColor: BCA_BLUE,
                },
            },
        },
        MuiInput: {
            borderBottom: `2px solid ${BCA_BLUE}`,
            underline: {
                '&:before': {
                    borderBottom: `2px solid ${BCA_BLUE}`,
                },
                '&:hover:not(.Mui-disabled):before': {
                    borderBottom: `2px solid ${BCA_BLUE}`,
                },
                '&:after': {
                    borderBottom: `2px solid ${BCA_BLUE}`,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                '&.custom-svg': {
                    color: BCA_BLUE,
                },
                '& .delivery_svg__a': {
                    stroke: BCA_BLUE,
                },
                '&.pickup-person': {
                    fill: BCA_BLUE,
                },
                '& .location_svg__a': {
                    stroke: BCA_BLUE,
                },
                '&.checkout-payment-payment-img': {
                    '& .payment_svg__b': {
                        stroke: BCA_BLUE,
                    },
                    '& g': {
                        stroke: BCA_BLUE,
                    },
                },
            },
        },
        MuiCheckbox: {
            root: {
                color: PRIMARY,
            },
            colorPrimary: {
                '&$checked': {
                    color: BCA_BLUE,
                },
            },
        },
        MuiDialogTitle: {
            root: {
                '&.custom-dialog--title': {
                    backgroundColor: BCA_BLUE,
                },
            },
        },
    },
});

export default theme;
