/* eslint-disable react/no-danger */
/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable radix */
/* eslint-disable max-len */
import React from 'react';
import App from 'next/app';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '@theme_theme';
import customTheme from '@theme/theme-custom';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { appWithTranslation } from '@i18n';
import {
    features, sentry, checkoutKeyCookies,
} from '@config';
import {
    getLoginInfo, getAuthInfo, getConfig, getLastPathWithoutLogin, getLoginInfoDashboard,
} from '@helper_auth';
import { setResolver, testLocalStorage } from '@helper_localstorage';
import { RewriteFrames } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { unregister } from 'next-offline/runtime';

import TagManager from 'react-gtm-module';
import PageProgressLoader from '@common_loaders/PageProgress';
import getNextConfig from 'next/config';
import Notification from '@lib_firebase/notification';
import firebase from '@lib_firebase/index';
import routeMiddleware from '@middleware_route';

import * as Sentry from '@sentry/node';
import ModalCookies from '@core_modules/theme/components/modalCookies';
import { BCA_BLUE } from '@core/theme/colors';
import { persistor, store } from '@core/redux/store';
import withRedux from 'next-redux-wrapper';
import { PersistGate } from 'redux-persist/integration/react';
import Head from 'next/head';

const { publicRuntimeConfig } = getNextConfig();

/*
 * ---------------------------------------------
 * SENTRY INITIALIZATION
 */
if (sentry.enabled && typeof publicRuntimeConfig !== 'undefined' && sentry.dsn[publicRuntimeConfig.appEnv]) {
    const distDir = `${publicRuntimeConfig.rootDir}/.next`;
    Sentry.init({
        enabled: process.env.NODE_ENV === sentry.enableMode,
        integrations: [
            new RewriteFrames({
                iteratee: (frame) => {
                    // eslint-disable-next-line no-param-reassign
                    frame.filename = frame.filename.replace(distDir, 'app:///_next');
                    return frame;
                },
            }),
            new Integrations.BrowserTracing(),
        ],
        environment: publicRuntimeConfig.appEnv,
        dsn: sentry.dsn[publicRuntimeConfig.appEnv],
        tracesSampleRate: 0.5,
    });
}

class MyApp extends App {
    constructor(props) {
        super(props);
        this.isLogin = false;
    }

    static async getInitialProps({ Component, ctx }) {
        let pageProps = {};

        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps(ctx);
        }
        const {
            res, pathname, query, req, ...other
        } = ctx;

        /*
         * ---------------------------------------------
         * MAINTAIN LOGIN FLAG
         * check if login from server
         */
        let isLogin = 0;
        let isAuth = 0;
        let isLoginDashboard = 0;
        let config = null;
        let lastPathNoAuth = '';
        const allcookie = req ? req.cookies : {};
        if (typeof window !== 'undefined') {
            isLogin = getLoginInfo();
            isLoginDashboard = getLoginInfoDashboard();
            isAuth = getAuthInfo();
            config = getConfig();
            lastPathNoAuth = getLastPathWithoutLogin();
        } else {
            isLogin = allcookie[checkoutKeyCookies.flagLogin] || 0;
            isLoginDashboard = allcookie[checkoutKeyCookies.flagLoginDashboard] || 0;
            isAuth = allcookie[checkoutKeyCookies.flagAuthorization] || 0;
            config = allcookie[checkoutKeyCookies.config] || '';
            if (config && config != null && typeof config === 'string') {
                config = JSON.parse(config);
            }
            lastPathNoAuth = req.session && typeof req.session !== 'undefined' && req.session.lastPathNoAuth && typeof req.session.lastPathNoAuth !== 'undefined'
                ? req.session.lastPathNoAuth
                : '/';
        }
        isLogin = parseInt(isLogin);
        isAuth = parseInt(isAuth);

        /*
         * ---------------------------------------------
         * [COOKIES] OTHER
         */
        const app_cookies = { cookies_currency: req ? req.cookies.app_currency : null };

        /*
         * ---------------------------------------------
         * CALLING ROUTING MIDDLEWARE
         */
        routeMiddleware({
            res,
            req,
            query,
            pathname,
            isLogin: publicRuntimeConfig.mode === '' || publicRuntimeConfig.mode === 'dashboard' ? isLoginDashboard : isLogin,
            lastPathNoAuth,
        });

        /*
         * ---------------------------------------------
         * GET CONFIGURATIONS FROM COOKIES
         * TO BE PROVIDED INTO PAGE PROPS
         */

        /*
         * ---------------------------------------------
         * RETURNS
         */
        let token;
        if (req && req.session && req.session.token) {
            token = req.session.token;
        }
        return {
            pageProps: {
                ...pageProps,
                app_cookies,
                isLogin,
                isAuth,
                config,
                token,
            },
        };
    }

    componentDidMount() {
        /*
         * ---------------------------------------------
         * ADDING CUSTOM SERVICE WORKER
         */
        if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production' && typeof document !== 'undefined') {
            if (document.readyState === 'complete') {
                this.registerServiceWorker();
            } else {
                window.addEventListener('load', () => {
                    this.registerServiceWorker();
                });
            }
        }

        /*
         * ---------------------------------------------
         * FIREBASE INITIALIZATION
         */
        if (features.firebase.pushNotification.enabled) {
            // initial firebase messaging
            Notification.init();
            // handle if have message on focus
            try {
                const messaging = firebase.messaging();
                // Handle incoming messages. Called when:
                // - a message is received while the app has focus
                // - the user clicks on an app notification created by a service worker
                //   `messaging.setBackgroundMessageHandler` handler.
                messaging.onMessage((payload) => {
                    navigator.serviceWorker.ready.then((registration) => {
                        // This prevents to show one notification for each tab
                        setTimeout(() => {
                            console.log('[firebase-messaging-sw.js] Received foreground message ', payload);
                            const lastNotification = localStorage.getItem('lastNotification');
                            const isDifferentContent = payload.data.updated_date !== lastNotification;
                            if (isDifferentContent) {
                                localStorage.setItem('lastNotification', payload.data.updated_date + payload.data.title);
                                registration.showNotification(payload.data.title, {
                                    body: payload.data.body,
                                    vibrate: [200, 100, 200, 100, 200, 100, 200],
                                    icon: payload.data.icons || '',
                                    image: payload.data.image || '',
                                    requireInteraction: true,
                                    data: payload.data,
                                });
                            }
                        }, Math.random() * 1000);
                    });
                });
            } catch (err) {
                console.log(err);
            }
        }

        /*
         * LAZY LOADING FONTS
         * Use this to load non critical fonts
         */
        // Fonts();

        /*
         * ---------------------------------------------
         * REMOVE THE SERVER SIDE INJECTED CSS
         * This is for speed performanc purpose
         */
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }

        /*
         * ---------------------------------------------
         * GTM INITIALIZATION
         */
        const config = this.props.pageProps?.config || null;
        const tagManagerArgs = {
            gtmId: config?.gtm || '',
        };
        if (config?.gtm) TagManager.initialize(tagManagerArgs);

        /*
         * ---------------------------------------------
         * COOKIE CLEARANCE
         * remove config cookie if the page is reloaded
         */
        if (typeof window !== 'undefined') {
            window.onbeforeunload = function () {
                setResolver({});
            };
        }
    }

    componentWillUnmount() {
        unregister();
    }

    registerServiceWorker() {
        navigator.serviceWorker.register('/service-worker.js').then(
            (registration) => {
                console.log('Service Worker registration successful with scope: ', registration.scope);
            },
            (err) => {
                console.log('Service Worker registration failed: ', err);
            },
        );
    }

    createCustomTheme() {
        return createMuiTheme;
    }

    render() {
        const { Component, pageProps } = this.props;
        /*
        Theme configurations based on mode used, if in `checkout` mode and the `primaryColor` prop is provided,
        use custom theme. Also provided global classes using styled-jsx syntax,
        for some components that are not available on the Material-UI theme by default.
        */
        const layoutTheme = publicRuntimeConfig.mode !== 'dashboard' && pageProps.config?.primaryColor
            ? customTheme({ primaryColor: pageProps.config.primaryColor })
            : theme;

        if (typeof window !== 'undefined' && testLocalStorage() === false) {
            // not available
            return (
                <ThemeProvider theme={layoutTheme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <ModalCookies {...pageProps} />
                </ThemeProvider>
            );
        }

        const customFavicon = !!pageProps?.config?.brands?.length && pageProps.config.brands[0]?.favicon;
        if (typeof window !== 'undefined' && customFavicon) {
            const LinkEl = document.querySelector("link[rel='icon']");
            if (LinkEl) {
                LinkEl.href = customFavicon;
            }
        }

        return (
            <>
                <Head>
                    {
                        pageProps?.config?.measurementId && (
                            <>
                                <script async src={`https://www.googletagmanager.com/gtag/js?id=${pageProps?.config?.measurementId}`} />
                                <script
                                    dangerouslySetInnerHTML={{
                                        __html: `
                                        window.dataLayer = window.dataLayer || [];
                                        function gtag(){dataLayer.push(arguments);}
                                        gtag('js', new Date());
                                        gtag('config', '${pageProps?.config?.measurementId}', {
                                            'client_id': getClientIdFromUrl(),
                                            'session_id': getSessionIdFromUrl()
                                        });
                                        `,
                                    }}
                                />
                            </>
                        )
                    }
                </Head>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={layoutTheme}>
                        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                        <CssBaseline />
                        <PageProgressLoader />
                        <Component {...pageProps} />
                    </ThemeProvider>
                </PersistGate>
                <style jsx global>
                    {`
                        .delivery-item-active {
                            background-color: ${pageProps.config?.primaryColor || BCA_BLUE};
                        }
                        .MuiAutocomplete-root {
                            border-bottom: 2px solid ${pageProps.config?.primaryColor || BCA_BLUE};
                        }
                        .MuiAutocomplete-root label {
                            color: ${pageProps.config?.primaryColor || BCA_BLUE};
                        }
                        .otp-resend-link {
                            color: ${pageProps.config?.primaryColor || BCA_BLUE};
                        }
                    `}
                </style>
            </>
        );
    }
}

const makeStore = () => store;
export default withRedux(makeStore)(appWithTranslation(MyApp));
