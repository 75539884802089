import _app from '@core_modules/theme/pages/_app';
import { features } from '@config';

/**
 * Import global css
 * */
import '../core/styles/index.css';
import '../core/styles/mediaquery.css';
import '../core/styles/flexboxgrid.min.css';

export default _app;
